import React, { lazy, Suspense, useEffect } from 'react';
import './index.css';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { FormikWrapper } from './components/Main/FormikWrapper';
import { AppForm } from './components/AppForm/AppForm';
import { Loader } from './components/components/Loader';
import { LeadWrapper } from './components/Main/LeadWrapper';
import { OpportunityWrapper } from './components/Main/OpportunityWrapper';
import SalesforceDetails from './components/Salesforce/SalesforceDetails';

const Maintenance = lazy(() => import('./components/Maintenance/Maintenance'));
const ErrorScreen = lazy(() => import('./components/Error/ErrorScreen'));
const SuccessScreen = lazy(() => import('./components/Success/SuccessPage'));

export const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname === '/form') {
      navigate('/', { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Suspense fallback={<Loader message="Please wait..." />}>
      <Routes>
        <Route exact path="/" element={<SalesforceDetails />} />
        <Route exact path="/error" element={<ErrorScreen />} />
        <Route exact path="/success" element={<SuccessScreen />} />
        <Route path="/maintenance" element={<Maintenance />} />
        <Route path="/" element={<FormikWrapper />}>
          <Route exact path="/lead/:id" element={<LeadWrapper />} />
          <Route exact path="/opportunity/:id" element={<OpportunityWrapper />} />
          <Route path="/form" element={<AppForm />} />
        </Route>
      </Routes>
    </Suspense>
  );
};
