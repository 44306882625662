import React from 'react';
import { css, cx } from 'emotion';
import { Tooltip } from './Tooltip';
import { HelpModal } from './HelpModal';

const descriptionStyle = css`
  font-size: 1rem;
  line-height: 1.5em;
  color: var(--dark);
  display: inline;
`;
const descriptionContainerStyle = css`
  margin-bottom: 0.5rem;
`;
const centerTooltipIconStyle = css`
  margin-left: 0.25rem;

  > img {
    margin-bottom: 0.25rem;
  }
`;
const largeMarginBottomStyle = css`
  margin-bottom: 1.5rem;
`;
const marginRightIconStyle = css`
  margin-left: 0.5rem;
`;
const inlineStyle = css`
  display: inline-flex;
`;

export const InputDescription = props => {
  const { text, tooltipText, id, largeMarginBottom = false, helpTextChildren, helpTitle } = props;
  return (
    <div
      className={cx(
        descriptionContainerStyle,
        largeMarginBottom && largeMarginBottomStyle,
        helpTextChildren && inlineStyle
      )}
    >
      <p className={descriptionStyle}>{text}</p>
      {tooltipText && (
        <Tooltip id={id} customStyle={centerTooltipIconStyle}>
          <p>{tooltipText}</p>
        </Tooltip>
      )}
      {helpTextChildren && (
        <HelpModal helpTextChildren={helpTextChildren} helpTitle={helpTitle} customStyle={marginRightIconStyle} />
      )}
    </div>
  );
};
