import React from 'react';
import { css } from 'emotion';
import { PageTitle } from './PageTitle';

const headingStyle = css`
  > h1 {
    color: var(--dark);
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.5em;
    margin-bottom: 0.75rem;
  }
  > h4 {
    color: var(--dark);
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.5em;
    margin-bottom: 0.75rem;
  }
  > p {
    color: var(--dark);
    font-size: 0.875rem;
    line-height: 1.21em;
    margin-bottom: 0.75rem;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;
const pageStyle = css`
  > p {
    margin-bottom: 1rem;
    font-size: 1rem;
    color: var(--dark);
    line-height: 1.5em;
    @media (max-width: 768px) {
      max-height: calc(100vh - 15rem);
      overflow: auto;
    }
  }
  > h4 {
    font-size: 1.25rem;
    line-height: 1.2em;
    letter-spacing: -0.03em;
    color: var(--dark);
    margin-bottom: 1rem;
  }
`;

export const PolicyDisclaimerText = props => {
  const { isOnMainSection } = props;
  return (
    <section className={isOnMainSection ? pageStyle : headingStyle}>
      <PageTitle title="Important Policy Information" />
      <p>
        This policy has been designed to meet the demands and needs of a UK based business and/or company seeking
        insurance for their hospitality business. Policies are arranged on a non-advised basis which means we do not
        make a personal recommendation on the suitability of the product. We provide you with sufficient information to
        enable you to make an informed decision as to whether the policies and the level of cover provided will meet
        your individual demands and needs.
      </p>
      <h4>Disclosure</h4>
      <p>
        Please ensure that the information provided by you is correct and that you disclose material circumstances to
        make a fair presentation of the risk. Should the you decide to proceed with the quotation, these details will
        form the basis of the insurance contract. Incorrect information could invalidate all or part of the Policy.
      </p>
    </section>
  );
};
