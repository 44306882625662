import React from 'react';
import { css, cx } from 'emotion';
import isArray from 'lodash/isArray';

const rightSidebarWidthStyle = css`
  grid-area: info;
  padding: 2.5rem 1.5rem 2.5rem 1.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    height: unset;
    background-color: unset;
    width: unset;
    padding: 0;
  }
`;
const hasChildContainerStyle = css`
  background-color: var(--silver);
`;
const noChildContainerStyle = css`
  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const RightSidebar = props => {
  const { children } = props;
  const hasChildren = arr => (isArray(arr) ? !arr.every(v => v === false) : false);
  return (
    <aside
      className={cx(rightSidebarWidthStyle, hasChildren(children) ? hasChildContainerStyle : noChildContainerStyle)}
    >
      {children}
    </aside>
  );
};
