import { css, cx } from 'emotion';
import React from 'react';

const buttonContainerStyle = css`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding-top: 1rem;
  margin-top: auto;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: min-content 1fr;
    > button:last-child {
      width: 100%;
    }
  }
`;
const stickyBottomStyle = css`
  position: sticky;
  padding: 1rem 0;
  margin-bottom: 0;
  background-color: var(--white);
  bottom: 0;
  @media (max-width: 768px) {
    left: 0;
    right: 0;
  }
  border-top: 2px solid var(--primaryBrand);
  &:before {
    content: '';
    height: 2px;
    position: absolute;
    top: -2px;
    left: -1rem;
    right: -1rem;
    background-color: var(--primaryBrand);
  }
`;

export const ButtonContainer = props => {
  const { children, customAlignment, stickyBottom } = props;
  return (
    <div className={cx(buttonContainerStyle, customAlignment, stickyBottom ? stickyBottomStyle : '')}>{children}</div>
  );
};
