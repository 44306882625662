import { parse } from 'qs';
import pick from 'lodash/pick';

export function getUtmFields(location) {
  const searchObject = parse(location.search, { ignoreQueryPrefix: true });
  const utmValues = pick(searchObject, [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
    'utm_offer',
    'utm_partner',
    'utm_referrer',
    'GCLID',
    'gclid',
    'organic_source',
    'organic_source_str',
    'gaclientid',
    'traffic_source',
    'first_utm_campaign',
    'first_utm_source',
    'first_utm_term',
    'first_utm_medium',
    'first_utm_content',
    'handl_original_ref',
    'handl_landing_page',
    'handl_ref',
    'handl_ref_domain',
    'handl_url'
  ]);
  const otherValues = pick(searchObject, ['covertype']);
  return { utmValues, otherValues };
}
