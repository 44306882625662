import React, { lazy, Suspense, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useFormikContext } from 'formik';
import { css } from 'emotion';
import { stepStore } from '../../store/step-store';
import { stateStore } from '../../store/state-store';
import { Loader } from '../components/Loader';
import { validationScheme } from '../../validation/validationScheme';

const PolicyDisclaimer = lazy(() => import('./components/PolicyDisclaimer'));
const BusinessType = lazy(() => import('./components/BusinessType'));
const CategoryCuisine = lazy(() => import('./components/CategoryCuisine'));
const AnnualTurnover = lazy(() => import('./components/AnnualTurnover'));
const NumberOfEmployees = lazy(() => import('./components/NumberOfEmployees'));
const BasicInfo = lazy(() => import('./components/BasicInfo'));
const Experience = lazy(() => import('./components/Experience'));
const Claims = lazy(() => import('./components/Claims'));
const BusinessPremises = lazy(() => import('./components/BusinessPremises'));
const ConstructionSpecification = lazy(() => import('./components/ConstructionSpecification'));
const PremisesDetails = lazy(() => import('./components/PremisesDetails'));
const ExposureToDisasters = lazy(() => import('./components/ExposureToDisasters'));
const Installations = lazy(() => import('./components/Installations'));
const KitchenInfo = lazy(() => import('./components/KitchenInfo'));
const CateringInfo = lazy(() => import('./components/CateringInfo'));
const EntertainmentInfo = lazy(() => import('./components/EntertainmentInfo'));
const StaffGuestrooms = lazy(() => import('./components/StaffGuestrooms'));
const Delivery = lazy(() => import('./components/Delivery'));
const Miscellaneous = lazy(() => import('./components/Miscellaneous'));
const SecurityRequirements = lazy(() => import('./components/SecurityRequirements'));
const PremisesProtection = lazy(() => import('./components/PremisesProtection'));
const FireDetection = lazy(() => import('./components/FireDetection'));
const VideoSurveillance = lazy(() => import('./components/VideoSurveillance'));
const HealthSafetyPolicy = lazy(() => import('./components/HealthSafetyPolicy'));
const SumsInsured = lazy(() => import('./components/SumsInsured'));
const PointOfContact = lazy(() => import('./components/PointOfContact'));
const CoverStart = lazy(() => import('./components/CoverStart'));
const AcceptanceCriteria = lazy(() => import('./components/AcceptanceCriteria'));

const containerStyle = css`
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  padding: 1.5rem 1rem 0 1rem;
  height: 100%;
  @media (min-width: 1024px) {
    min-height: calc(100vh - 7rem);
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    min-height: calc(100vh - 4.625rem);
  }
`;

export const AppForm = observer(() => {
  const { step } = stepStore;
  const { handleSubmit } = useFormikContext();

  const handlePreviousStep = () => {
    stepStore.previousStep();
  };
  const stepper = [
    {
      name: 'Policy Disclaimer',
      component: PolicyDisclaimer,
      validation: validationScheme.policyDisclaimer
    },
    {
      name: 'Business Type',
      component: BusinessType,
      validation: validationScheme.businessType
    },
    ...(!stateStore.getIsPubOrWine()
      ? [
          {
            name: 'Category/Cuisine',
            component: CategoryCuisine,
            validation: validationScheme.categoryCuisine
          }
        ]
      : []),
    {
      name: 'Annual Turnover',
      component: AnnualTurnover,
      validation: validationScheme.annualTurnover
    },
    {
      name: 'Number of Employees',
      component: NumberOfEmployees,
      validation: validationScheme.numberOfEmployees
    },
    {
      name: 'Basic Info',
      component: BasicInfo,
      validation: validationScheme.basicInfo
    },
    {
      name: 'Experience',
      component: Experience,
      validation: validationScheme.experience
    },
    {
      name: 'Claims',
      component: Claims,
      validation: validationScheme.claims
    },
    {
      name: 'Business Premises',
      component: BusinessPremises,
      validation: validationScheme.businessPremises
    },
    {
      name: 'Construction Specification',
      component: ConstructionSpecification,
      validation: validationScheme.constructionSpecification
    },
    {
      name: 'Premises Details',
      component: PremisesDetails,
      validation: validationScheme.premisesDetails
    },
    {
      name: 'Exposure To Disasters',
      component: ExposureToDisasters,
      validation: validationScheme.exposureToDisasters
    },
    {
      name: 'Installations',
      component: Installations,
      validation: validationScheme.installations
    },
    {
      name: 'Kitchen Info',
      component: KitchenInfo,
      validation: validationScheme.kitchenInfo
    },
    {
      name: 'Catering Info',
      component: CateringInfo,
      validation: validationScheme.cateringInfo
    },
    ...(stateStore.getIsShop()
      ? []
      : [
          {
            name: 'Entertainment Info',
            component: EntertainmentInfo,
            validation: validationScheme.entertainmentInfo
          },
          {
            name: 'Staff And Guestrooms',
            component: StaffGuestrooms,
            validation: validationScheme.staffGuestrooms
          }
        ]),
    {
      name: 'Delivery',
      component: Delivery,
      validation: validationScheme.delivery
    },
    {
      name: 'Miscellaneous',
      component: Miscellaneous,
      validation: validationScheme.miscellaneous
    },
    {
      name: 'Security Requirements',
      component: SecurityRequirements,
      validation: validationScheme.securityRequirements
    },
    {
      name: 'Premises Protection',
      component: PremisesProtection,
      validation: validationScheme.premisesProtection
    },
    ...(stateStore.getGuestrooms() || stateStore.getFullRangeFrying()
      ? [
          {
            name: 'Fire Detection',
            component: FireDetection,
            validation: validationScheme.fireDetection
          }
        ]
      : []),
    {
      name: 'Video Surveillance',
      component: VideoSurveillance,
      validation: validationScheme.videoSurveillance
    },
    ...(stateStore.getMoreThanFourEmployees()
      ? [
          {
            name: 'Health And Safety Policy',
            component: HealthSafetyPolicy,
            validation: validationScheme.healthSafetyPolicy
          }
        ]
      : []),
    {
      name: 'Sums Insured',
      component: SumsInsured,
      validation: validationScheme.sumsInsured
    },
    {
      name: 'Point of Contact',
      component: PointOfContact,
      validation: validationScheme.pointOfContact
    },
    {
      name: 'Cover Start',
      component: CoverStart,
      validation: validationScheme.coverStart
    },
    {
      name: 'Acceptance Criteria',
      component: AcceptanceCriteria,
      validation: validationScheme.acceptanceCriteria,
      action: 'savePolicy'
    }
  ];
  useEffect(() => {
    stateStore.setStepper(stepper);
  }, [stepper]);

  const Component = stepper[step].component;
  return (
    <section className={containerStyle}>
      <Suspense fallback={<Loader message="Please wait..." />}>
        <Component
          handlePreviousStep={() => {
            handlePreviousStep();
            stateStore.setStepper(stepper);
          }}
          handleNextStep={handleSubmit}
        />
      </Suspense>
    </section>
  );
});
