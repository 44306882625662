import React, { Fragment, useEffect, useRef } from 'react';
import { css, cx } from 'emotion';
import { observer } from 'mobx-react';
import { ReactComponent as CircleCurrentIcon } from '../../assets/circleCurrent.svg';
import { ReactComponent as MinusIcon } from '../../assets/minusIcon.svg';
import { ReactComponent as CircleCheckedIcon } from '../../assets/circleFull.svg';
import { stepStore } from '../../store/step-store';
import { stateStore } from '../../store/state-store';
import { useOnScreen } from '../../utils/useOnScreen';

const sidebarContainer = css`
  position: relative;
  grid-area: aside;
  background-color: var(--silver);
`;

const leftSidebarStyle = css`
  position: sticky;
  left: 0;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 1rem;
  top: 0;
  @media (min-width: 769px) and (max-width: 1024px) {
    min-height: calc(100vh - 5.625rem);
  }
  min-height: calc(100vh - 7rem);
  max-height: 100vh;
  overflow: auto;
  background: var(--silver);
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    display: none;
  }
`;
const progressContainerStyle = css`
  position: relative;
  width: 100%;
  background-color: var(--gray);
  border: 1px solid var(--gray);
  border-radius: 8px;
  > {
    color: var(--gray);
    font-size: 0.75rem;
    line-height: 1.33em;
  }
`;
const barContainerStyle = css`
  height: 1rem;
  background-color: var(--primaryBrand);
  border-radius: 8px;
  text-align: center;
  line-height: 1rem;
  color: var(--gray);
  transition: width 0.75s 0.1s;
`;

const mobileProgressStyle = css`
  display: none;
  @media (max-width: 768px) {
    grid-area: aside;
    display: block;
    margin: 0 1rem;
  }
`;
const currentCircleStyle = css`
  width: 1.5rem;
  height: 1.5rem;
  > circle:first-child {
    fill: var(--primaryBrand);
  }
  > circle:last-child {
    fill: var(--white);
  }
`;
const checkedCircleStyle = css`
  width: 1.5rem;
  height: 1.5rem;
  > circle {
    fill: var(--primaryBrand);
  }
`;
const minusCircleStyle = css`
  height: 0.25rem;
  width: 1rem;
  > rect {
    fill: var(--gray);
  }
`;
const stepperTitleStyle = css`
  display: grid;
  grid-template-columns: 1.5rem 1fr;
  grid-gap: 1rem;
  font-size: 0.875rem;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1.14em;
  color: var(--dark);
  margin-bottom: 1.25rem;
  list-style-type: none;
  > svg {
    margin-right: 1rem;
    width: 1rem;
    height: 1rem;
  }
  &:first-child {
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const currentPageTextStyle = css`
  color: var(--primaryBrand);
`;

export const LeftSidebar = observer(() => {
  const ref = useRef(null);
  const visibleHeight = useOnScreen(ref);
  const parentRef = useRef(null);
  const stepperRef = useRef(null);
  const nextStepRef = useRef(null);
  const childHeight = useOnScreen(nextStepRef.current ? nextStepRef : stepperRef);

  const { step: currentStep, previousStepValue } = stepStore;
  const stepper = stateStore.getStepper();

  const progress = ((currentStep + 1) / stepper.length) * 100;
  const barWidthStyle = css`
    width: ${progress}%;
  `;
  const customSidebarHeightStyle = css`
    height: ${visibleHeight}px !important;
  `;

  useEffect(() => {
    if (childHeight < stepperRef?.current?.offsetHeight && ![0, 1].includes(currentStep)) {
      if (previousStepValue < currentStep) {
        parentRef.current.scrollTop += stepperRef.current.offsetHeight * 2 + 20;
      } else {
        parentRef.current.scrollTop -= stepperRef.current.offsetHeight * 2 + 20;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepperRef.current?.innerText]);

  return (
    <aside className={sidebarContainer} ref={ref}>
      <div ref={parentRef} className={cx(leftSidebarStyle, customSidebarHeightStyle)}>
        <ol>
          {stepper.map((step, index) => (
            <Fragment key={step.name}>
              {step.name !== '' && (
                <li
                  className={cx(stepperTitleStyle, index <= currentStep ? currentPageTextStyle : '')}
                  ref={index === currentStep ? stepperRef : index === currentStep + 1 ? nextStepRef : null}
                >
                  {index < currentStep ? (
                    <CircleCheckedIcon className={checkedCircleStyle} />
                  ) : index === currentStep ? (
                    <CircleCurrentIcon className={currentCircleStyle} />
                  ) : (
                    <MinusIcon className={minusCircleStyle} />
                  )}
                  {step.name}
                </li>
              )}
            </Fragment>
          ))}
        </ol>
      </div>
      <div className={mobileProgressStyle}>
        <div className={progressContainerStyle}>
          <div className={cx(barContainerStyle, barWidthStyle)} />
        </div>
      </div>
    </aside>
  );
});
