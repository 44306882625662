import { action, makeObservable, observable } from 'mobx';

export class StepStore {
  step = window.innerWidth < 768 ? 0 : 1;

  previousStepValue = 0;

  constructor() {
    makeObservable(this, {
      step: observable,
      previousStepValue: observable,
      nextStep: action,
      previousStep: action,
      skipNSteps: action,
      returnNSteps: action,
      goToStep: action
    });
    this.step = window.innerWidth < 768 ? 0 : 1;

    this.previousStepValue = 0;
  }

  nextStep = () => {
    this.previousStepValue = this.step;
    this.step++;
  };

  previousStep = () => this.step--;

  skipNSteps = n => {
    this.previousStepValue = this.step;
    this.step = this.step + n;
  };

  returnNSteps = n => (this.step = this.step - n);

  goToStep = n => {
    this.previousStepValue = this.step;
    this.step = n;
  };
}

export const stepStore = new StepStore();
