import React from 'react';
import { css, cx, keyframes } from 'emotion';

const buttonStyle = css`
  text-align: center;
  padding: 0.5rem 0.5rem;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.2em;
  @media (max-width: 768px) {
    margin-bottom: env(safe-area-inset-bottom);
  }
`;

const anim1 = keyframes`
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
`;

const loaderStyle = css`
  top: -1.8rem;
  border-radius: 50%;
  position: relative;
  width: 0.75rem;
  height: 0.75rem;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: ${anim1} 1.8s infinite ease-in-out;
  animation: ${anim1} 1.8s infinite ease-in-out;
  :before,
  :after {
    border-radius: 50%;
    width: 0.75rem;
    height: 0.75rem;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: ${anim1} 1.8s infinite ease-in-out;
    animation: ${anim1} 1.8s infinite ease-in-out;
  }
  color: var(--white);
  font-size: 0.75rem;
  margin: 0 auto;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  :before,
  :after {
    content: '';
    position: absolute;
    top: 0;
  }
  :after {
    left: 1.25rem;
  }
  :before {
    left: -1.25rem;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
`;

const primaryButton = css`
  background: var(--primaryBrand);
  color: var(--white);
  border: 2px solid transparent;
  width: 10rem;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const secondaryButton = css`
  background-color: var(--white);
  border: 2px solid var(--primaryBrand);
  color: var(--primaryBrand);
`;
const disabledStyle = css`
  background-color: var(--silver);
`;
const iconButtonContainerStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const afterIconStyle = css`
  margin-left: 0.5rem;
`;
const beforeIconStyle = css`
  margin-right: 0.5rem;
`;
const mainIconStyle = css``;

const sfButtonStyle = css`
  margin-top: 0.625rem;
  background-color: #fff;
  color: #3e4c59;
  font-size: 1.25rem;
  font-weight: 600;
  font-family: Lato, sans-serif;
  line-height: 1.7;
  padding: 0.313rem;
  border: 1px solid #ddd;
  border-radius: 3px;
  cursor: pointer;
`;

export const Button = props => {
  const {
    label,
    handleClick,
    secondary,
    type = 'button',
    disabled,
    customStyle,
    mainIcon,
    beforeIcon,
    afterIcon,
    loading,
    salesForce
  } = props;
  return (
    <button
      className={cx(
        buttonStyle,
        secondary ? secondaryButton : salesForce ? sfButtonStyle : primaryButton,
        customStyle,
        disabled ? disabledStyle : ''
      )}
      disabled={disabled}
      onClick={handleClick}
      type={type}
    >
      {!loading &&
        (beforeIcon || afterIcon || mainIcon ? (
          <div className={iconButtonContainerStyle}>
            {beforeIcon && <img src={beforeIcon} alt={label} className={beforeIconStyle} />}
            {mainIcon && <img src={mainIcon} alt={label} className={mainIconStyle} />}
            <p>{label}</p>
            {afterIcon && <img src={afterIcon} alt={label} className={afterIconStyle} />}
          </div>
        ) : (
          label
        ))}
      {loading && <div className={loaderStyle} />}
    </button>
  );
};
